const _black = "#000"
const _white = "#fff"
const _transparent = "transparent"
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _current = "currentColor"
const _emerald = {"50":"#CAF1DF","100":"#B9EDD6","200":"#99E4C3","300":"#79DCAF","400":"#58D39C","500":"#38CB89","600":"#2AA16C","700":"#1E744E","800":"#134830","900":"#071B12","950":"#010503","DEFAULT":"#38CB89"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _pink = {"50":"#FF85C2","100":"#FF70B8","200":"#FF47A3","300":"#FF1F8F","400":"#F5007A","500":"#CC0066","600":"#94004A","700":"#5C002E","800":"#240012","900":"#000000","950":"#000000","DEFAULT":"#CC0066"}
const _error = {"50":"#FF85A7","100":"#FF7099","200":"#FF477B","300":"#FF1F5E","400":"#F50045","500":"#CC003A","600":"#94002A","700":"#5C001A","800":"#24000A","900":"#000000","950":"#000000","DEFAULT":"#CC003A"}
const _amber = {"50":"#fffeea","100":"#fffbc4","200":"#fff784","300":"#ffec44","400":"#ffdc19","500":"#ffbc00","600":"#e49000","700":"#cc6e00","800":"#994e07","900":"#7e3f09","950":"#482100"}
const _none = {"50":"transparent","100":"transparent","200":"transparent","300":"transparent","400":"transparent","500":"transparent","600":"transparent","700":"transparent","800":"transparent","900":"transparent"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "black": _black, "white": _white, "transparent": _transparent, "red": _red, "current": _current, "emerald": _emerald, "gray": _gray, "pink": _pink, "error": _error, "amber": _amber, "none": _none, "primary": _primary, "cool": _cool, "cadet-blue": {"50":"#f6f7f8","100":"#eaecef","200":"#dadde3","300":"#c0c7d0","400":"#b0b7c3","500":"#8b93a6","600":"#7a8196","700":"#6d7288","800":"#5c5f71","900":"#4c4f5c","950":"#31323a"},  }
export { config as default, _black, _white, _transparent, _red, _current, _emerald, _gray, _pink, _error, _amber, _none, _primary, _cool }