import revive_payload_client_4sVQNw7RlN from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/proper-plan/proper-plan/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/proper-plan/proper-plan/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/home/runner/work/proper-plan/proper-plan/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/home/runner/work/proper-plan/proper-plan/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_8SbxDRbG6Y from "/home/runner/work/proper-plan/proper-plan/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import colors_244lXBzhnM from "/home/runner/work/proper-plan/proper-plan/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/runner/work/proper-plan/proper-plan/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/proper-plan/proper-plan/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "/home/runner/work/proper-plan/proper-plan/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _1_firebase_client_GtorZ4LfAB from "/home/runner/work/proper-plan/proper-plan/node_modules/@hiyield/nuxt-layer-firebase/plugins/1.firebase.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/proper-plan/proper-plan/plugins/sentry.client.ts";
import vueDraggable_T7DBzTsrY6 from "/home/runner/work/proper-plan/proper-plan/plugins/vueDraggable.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_8SbxDRbG6Y,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  _1_firebase_client_GtorZ4LfAB,
  sentry_client_shVUlIjFLk,
  vueDraggable_T7DBzTsrY6
]