export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"https://www.googletagmanager.com/gtag/js?id=G-Y1TSSVQBZ7","async":true},{"children":"\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', 'G-Y1TSSVQBZ7');\n          ","type":"text/javascript"},{"type":"text/javascript","innerHTML":"\n      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d4wdusda'\n      (function(){var w=window;var ic=w.Intercom;if(typeof ic===\"function\"){ic('reattach_activator');\n      ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];\n      i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');\n      s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d4wdusda';\n      var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};\n      if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'