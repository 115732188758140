import { default as _ai_45testBtSDYXA4ffMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_ai-test.vue?macro=true";
import { default as _buttonsNZqPTYw8caMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_buttons.vue?macro=true";
import { default as _calendarqT5nwHSxbjMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_calendar.vue?macro=true";
import { default as _cardsOyqUVJr0ohMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_cards.vue?macro=true";
import { default as _checkboxesjWAR51rKexMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_checkboxes.vue?macro=true";
import { default as _copy_45crafter_45inputfeWQpeGHt8Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_copy-crafter-input.vue?macro=true";
import { default as _inputsyrArQNoh5xMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_inputs.vue?macro=true";
import { default as _lightbulbEOCRcbUCehMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_lightbulb.vue?macro=true";
import { default as _notifications5NxMfKB4A0Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_notifications.vue?macro=true";
import { default as _text_45inputkEV6TgbRN2Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/_text-input.vue?macro=true";
import { default as create_45plan_45from_45templateip7gbqxgMYMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/create-plan-from-template.vue?macro=true";
import { default as image_45upload3sFCe60drCMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/image-upload.vue?macro=true";
import { default as _91planUid_93VPQ0uYXO50Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/plan-view/[planUid].vue?macro=true";
import { default as _91phaseUid_930FYVbvoZ00Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/task-row/[phaseUid].vue?macro=true";
import { default as _91taskId_935VtdLgXhu0Meta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/today-task-row/[taskId].vue?macro=true";
import { default as users_45task_45listJMFQbOBi4nMeta } from "/home/runner/work/proper-plan/proper-plan/pages/_components/users-task-list.vue?macro=true";
import { default as copy_45plan_45templatet9lD8P98S6Meta } from "/home/runner/work/proper-plan/proper-plan/pages/copy-plan-template.vue?macro=true";
import { default as indexVQ1n5GiVihMeta } from "/home/runner/work/proper-plan/proper-plan/pages/dashboard/index.vue?macro=true";
import { default as indexmS5UxWGCsuMeta } from "/home/runner/work/proper-plan/proper-plan/pages/index.vue?macro=true";
import { default as invitezwSD80x7xIMeta } from "/home/runner/work/proper-plan/proper-plan/pages/invite.vue?macro=true";
import { default as link_45testEROqSTW0ddMeta } from "/home/runner/work/proper-plan/proper-plan/pages/link-test.vue?macro=true";
import { default as _91uid_93Q0cWnGY6UxMeta } from "/home/runner/work/proper-plan/proper-plan/pages/my-plan-templates/[uid].vue?macro=true";
import { default as indexxwwLz765rdMeta } from "/home/runner/work/proper-plan/proper-plan/pages/my-plan-templates/index.vue?macro=true";
import { default as _91uid_934YVANbU3VQMeta } from "/home/runner/work/proper-plan/proper-plan/pages/my-plans/[uid].vue?macro=true";
import { default as indexg5FHe7L3oaMeta } from "/home/runner/work/proper-plan/proper-plan/pages/my-plans/index.vue?macro=true";
import { default as _91uid_934vAnPICossMeta } from "/home/runner/work/proper-plan/proper-plan/pages/plan-library/[uid].vue?macro=true";
import { default as indexs6q7wlVkX2Meta } from "/home/runner/work/proper-plan/proper-plan/pages/plan-library/index.vue?macro=true";
import { default as _91uid_93uoDEf3yz71Meta } from "/home/runner/work/proper-plan/proper-plan/pages/plan-sync-test/[uid].vue?macro=true";
import { default as _91uid_93SCobvFJ9X1Meta } from "/home/runner/work/proper-plan/proper-plan/pages/plan-template/[uid].vue?macro=true";
import { default as sign_45up_45lifetimejDqwKhK4KrMeta } from "/home/runner/work/proper-plan/proper-plan/pages/sign-up-lifetime.vue?macro=true";
import { default as sign_45upc98M2TrvK8Meta } from "/home/runner/work/proper-plan/proper-plan/pages/sign-up.vue?macro=true";
import { default as _91uid_93KemeEEFFFwMeta } from "/home/runner/work/proper-plan/proper-plan/pages/task-list-test/[uid].vue?macro=true";
import { default as indexXAxdlEefrUMeta } from "/home/runner/work/proper-plan/proper-plan/pages/user-settings/index.vue?macro=true";
export default [
  {
    name: _ai_45testBtSDYXA4ffMeta?.name ?? "_components-_ai-test",
    path: _ai_45testBtSDYXA4ffMeta?.path ?? "/_components/_ai-test",
    meta: _ai_45testBtSDYXA4ffMeta || {},
    alias: _ai_45testBtSDYXA4ffMeta?.alias || [],
    redirect: _ai_45testBtSDYXA4ffMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_ai-test.vue").then(m => m.default || m)
  },
  {
    name: _buttonsNZqPTYw8caMeta?.name ?? "_components-_buttons",
    path: _buttonsNZqPTYw8caMeta?.path ?? "/_components/_buttons",
    meta: _buttonsNZqPTYw8caMeta || {},
    alias: _buttonsNZqPTYw8caMeta?.alias || [],
    redirect: _buttonsNZqPTYw8caMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_buttons.vue").then(m => m.default || m)
  },
  {
    name: _calendarqT5nwHSxbjMeta?.name ?? "_components-_calendar",
    path: _calendarqT5nwHSxbjMeta?.path ?? "/_components/_calendar",
    meta: _calendarqT5nwHSxbjMeta || {},
    alias: _calendarqT5nwHSxbjMeta?.alias || [],
    redirect: _calendarqT5nwHSxbjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_calendar.vue").then(m => m.default || m)
  },
  {
    name: _cardsOyqUVJr0ohMeta?.name ?? "_components-_cards",
    path: _cardsOyqUVJr0ohMeta?.path ?? "/_components/_cards",
    meta: _cardsOyqUVJr0ohMeta || {},
    alias: _cardsOyqUVJr0ohMeta?.alias || [],
    redirect: _cardsOyqUVJr0ohMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_cards.vue").then(m => m.default || m)
  },
  {
    name: _checkboxesjWAR51rKexMeta?.name ?? "_components-_checkboxes",
    path: _checkboxesjWAR51rKexMeta?.path ?? "/_components/_checkboxes",
    meta: _checkboxesjWAR51rKexMeta || {},
    alias: _checkboxesjWAR51rKexMeta?.alias || [],
    redirect: _checkboxesjWAR51rKexMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_checkboxes.vue").then(m => m.default || m)
  },
  {
    name: _copy_45crafter_45inputfeWQpeGHt8Meta?.name ?? "_components-_copy-crafter-input",
    path: _copy_45crafter_45inputfeWQpeGHt8Meta?.path ?? "/_components/_copy-crafter-input",
    meta: _copy_45crafter_45inputfeWQpeGHt8Meta || {},
    alias: _copy_45crafter_45inputfeWQpeGHt8Meta?.alias || [],
    redirect: _copy_45crafter_45inputfeWQpeGHt8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_copy-crafter-input.vue").then(m => m.default || m)
  },
  {
    name: _inputsyrArQNoh5xMeta?.name ?? "_components-_inputs",
    path: _inputsyrArQNoh5xMeta?.path ?? "/_components/_inputs",
    meta: _inputsyrArQNoh5xMeta || {},
    alias: _inputsyrArQNoh5xMeta?.alias || [],
    redirect: _inputsyrArQNoh5xMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_inputs.vue").then(m => m.default || m)
  },
  {
    name: _lightbulbEOCRcbUCehMeta?.name ?? "_components-_lightbulb",
    path: _lightbulbEOCRcbUCehMeta?.path ?? "/_components/_lightbulb",
    meta: _lightbulbEOCRcbUCehMeta || {},
    alias: _lightbulbEOCRcbUCehMeta?.alias || [],
    redirect: _lightbulbEOCRcbUCehMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_lightbulb.vue").then(m => m.default || m)
  },
  {
    name: _notifications5NxMfKB4A0Meta?.name ?? "_components-_notifications",
    path: _notifications5NxMfKB4A0Meta?.path ?? "/_components/_notifications",
    meta: _notifications5NxMfKB4A0Meta || {},
    alias: _notifications5NxMfKB4A0Meta?.alias || [],
    redirect: _notifications5NxMfKB4A0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_notifications.vue").then(m => m.default || m)
  },
  {
    name: _text_45inputkEV6TgbRN2Meta?.name ?? "_components-_text-input",
    path: _text_45inputkEV6TgbRN2Meta?.path ?? "/_components/_text-input",
    meta: _text_45inputkEV6TgbRN2Meta || {},
    alias: _text_45inputkEV6TgbRN2Meta?.alias || [],
    redirect: _text_45inputkEV6TgbRN2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/_text-input.vue").then(m => m.default || m)
  },
  {
    name: create_45plan_45from_45templateip7gbqxgMYMeta?.name ?? "_components-create-plan-from-template",
    path: create_45plan_45from_45templateip7gbqxgMYMeta?.path ?? "/_components/create-plan-from-template",
    meta: create_45plan_45from_45templateip7gbqxgMYMeta || {},
    alias: create_45plan_45from_45templateip7gbqxgMYMeta?.alias || [],
    redirect: create_45plan_45from_45templateip7gbqxgMYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/create-plan-from-template.vue").then(m => m.default || m)
  },
  {
    name: image_45upload3sFCe60drCMeta?.name ?? "_components-image-upload",
    path: image_45upload3sFCe60drCMeta?.path ?? "/_components/image-upload",
    meta: image_45upload3sFCe60drCMeta || {},
    alias: image_45upload3sFCe60drCMeta?.alias || [],
    redirect: image_45upload3sFCe60drCMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/image-upload.vue").then(m => m.default || m)
  },
  {
    name: _91planUid_93VPQ0uYXO50Meta?.name ?? "_components-plan-view-planUid",
    path: _91planUid_93VPQ0uYXO50Meta?.path ?? "/_components/plan-view/:planUid()",
    meta: _91planUid_93VPQ0uYXO50Meta || {},
    alias: _91planUid_93VPQ0uYXO50Meta?.alias || [],
    redirect: _91planUid_93VPQ0uYXO50Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/plan-view/[planUid].vue").then(m => m.default || m)
  },
  {
    name: _91phaseUid_930FYVbvoZ00Meta?.name ?? "_components-task-row-phaseUid",
    path: _91phaseUid_930FYVbvoZ00Meta?.path ?? "/_components/task-row/:phaseUid()",
    meta: _91phaseUid_930FYVbvoZ00Meta || {},
    alias: _91phaseUid_930FYVbvoZ00Meta?.alias || [],
    redirect: _91phaseUid_930FYVbvoZ00Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/task-row/[phaseUid].vue").then(m => m.default || m)
  },
  {
    name: _91taskId_935VtdLgXhu0Meta?.name ?? "_components-today-task-row-taskId",
    path: _91taskId_935VtdLgXhu0Meta?.path ?? "/_components/today-task-row/:taskId()",
    meta: _91taskId_935VtdLgXhu0Meta || {},
    alias: _91taskId_935VtdLgXhu0Meta?.alias || [],
    redirect: _91taskId_935VtdLgXhu0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/today-task-row/[taskId].vue").then(m => m.default || m)
  },
  {
    name: users_45task_45listJMFQbOBi4nMeta?.name ?? "_components-users-task-list",
    path: users_45task_45listJMFQbOBi4nMeta?.path ?? "/_components/users-task-list",
    meta: users_45task_45listJMFQbOBi4nMeta || {},
    alias: users_45task_45listJMFQbOBi4nMeta?.alias || [],
    redirect: users_45task_45listJMFQbOBi4nMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/_components/users-task-list.vue").then(m => m.default || m)
  },
  {
    name: copy_45plan_45templatet9lD8P98S6Meta?.name ?? "copy-plan-template",
    path: copy_45plan_45templatet9lD8P98S6Meta?.path ?? "/copy-plan-template",
    meta: copy_45plan_45templatet9lD8P98S6Meta || {},
    alias: copy_45plan_45templatet9lD8P98S6Meta?.alias || [],
    redirect: copy_45plan_45templatet9lD8P98S6Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/copy-plan-template.vue").then(m => m.default || m)
  },
  {
    name: indexVQ1n5GiVihMeta?.name ?? "dashboard",
    path: indexVQ1n5GiVihMeta?.path ?? "/dashboard",
    meta: indexVQ1n5GiVihMeta || {},
    alias: indexVQ1n5GiVihMeta?.alias || [],
    redirect: indexVQ1n5GiVihMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexmS5UxWGCsuMeta?.name ?? "index",
    path: indexmS5UxWGCsuMeta?.path ?? "/",
    meta: indexmS5UxWGCsuMeta || {},
    alias: indexmS5UxWGCsuMeta?.alias || [],
    redirect: indexmS5UxWGCsuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invitezwSD80x7xIMeta?.name ?? "invite",
    path: invitezwSD80x7xIMeta?.path ?? "/invite",
    meta: invitezwSD80x7xIMeta || {},
    alias: invitezwSD80x7xIMeta?.alias || [],
    redirect: invitezwSD80x7xIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/invite.vue").then(m => m.default || m)
  },
  {
    name: link_45testEROqSTW0ddMeta?.name ?? "link-test",
    path: link_45testEROqSTW0ddMeta?.path ?? "/link-test",
    meta: link_45testEROqSTW0ddMeta || {},
    alias: link_45testEROqSTW0ddMeta?.alias || [],
    redirect: link_45testEROqSTW0ddMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/link-test.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93Q0cWnGY6UxMeta?.name ?? "my-plan-templates-uid",
    path: _91uid_93Q0cWnGY6UxMeta?.path ?? "/my-plan-templates/:uid()",
    meta: _91uid_93Q0cWnGY6UxMeta || {},
    alias: _91uid_93Q0cWnGY6UxMeta?.alias || [],
    redirect: _91uid_93Q0cWnGY6UxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/my-plan-templates/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexxwwLz765rdMeta?.name ?? "my-plan-templates",
    path: indexxwwLz765rdMeta?.path ?? "/my-plan-templates",
    meta: indexxwwLz765rdMeta || {},
    alias: indexxwwLz765rdMeta?.alias || [],
    redirect: indexxwwLz765rdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/my-plan-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_934YVANbU3VQMeta?.name ?? "my-plans-uid",
    path: _91uid_934YVANbU3VQMeta?.path ?? "/my-plans/:uid()",
    meta: _91uid_934YVANbU3VQMeta || {},
    alias: _91uid_934YVANbU3VQMeta?.alias || [],
    redirect: _91uid_934YVANbU3VQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/my-plans/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexg5FHe7L3oaMeta?.name ?? "my-plans",
    path: indexg5FHe7L3oaMeta?.path ?? "/my-plans",
    meta: indexg5FHe7L3oaMeta || {},
    alias: indexg5FHe7L3oaMeta?.alias || [],
    redirect: indexg5FHe7L3oaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/my-plans/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_934vAnPICossMeta?.name ?? "plan-library-uid",
    path: _91uid_934vAnPICossMeta?.path ?? "/plan-library/:uid()",
    meta: _91uid_934vAnPICossMeta || {},
    alias: _91uid_934vAnPICossMeta?.alias || [],
    redirect: _91uid_934vAnPICossMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/plan-library/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexs6q7wlVkX2Meta?.name ?? "plan-library",
    path: indexs6q7wlVkX2Meta?.path ?? "/plan-library",
    meta: indexs6q7wlVkX2Meta || {},
    alias: indexs6q7wlVkX2Meta?.alias || [],
    redirect: indexs6q7wlVkX2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/plan-library/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93uoDEf3yz71Meta?.name ?? "plan-sync-test-uid",
    path: _91uid_93uoDEf3yz71Meta?.path ?? "/plan-sync-test/:uid()",
    meta: _91uid_93uoDEf3yz71Meta || {},
    alias: _91uid_93uoDEf3yz71Meta?.alias || [],
    redirect: _91uid_93uoDEf3yz71Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/plan-sync-test/[uid].vue").then(m => m.default || m)
  },
  {
    name: _91uid_93SCobvFJ9X1Meta?.name ?? "plan-template-uid",
    path: _91uid_93SCobvFJ9X1Meta?.path ?? "/plan-template/:uid()",
    meta: _91uid_93SCobvFJ9X1Meta || {},
    alias: _91uid_93SCobvFJ9X1Meta?.alias || [],
    redirect: _91uid_93SCobvFJ9X1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/plan-template/[uid].vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45lifetimejDqwKhK4KrMeta?.name ?? "sign-up-lifetime",
    path: sign_45up_45lifetimejDqwKhK4KrMeta?.path ?? "/sign-up-lifetime",
    meta: sign_45up_45lifetimejDqwKhK4KrMeta || {},
    alias: sign_45up_45lifetimejDqwKhK4KrMeta?.alias || [],
    redirect: sign_45up_45lifetimejDqwKhK4KrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/sign-up-lifetime.vue").then(m => m.default || m)
  },
  {
    name: sign_45upc98M2TrvK8Meta?.name ?? "sign-up",
    path: sign_45upc98M2TrvK8Meta?.path ?? "/sign-up",
    meta: sign_45upc98M2TrvK8Meta || {},
    alias: sign_45upc98M2TrvK8Meta?.alias || [],
    redirect: sign_45upc98M2TrvK8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91uid_93KemeEEFFFwMeta?.name ?? "task-list-test-uid",
    path: _91uid_93KemeEEFFFwMeta?.path ?? "/task-list-test/:uid()",
    meta: _91uid_93KemeEEFFFwMeta || {},
    alias: _91uid_93KemeEEFFFwMeta?.alias || [],
    redirect: _91uid_93KemeEEFFFwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/task-list-test/[uid].vue").then(m => m.default || m)
  },
  {
    name: indexXAxdlEefrUMeta?.name ?? "user-settings",
    path: indexXAxdlEefrUMeta?.path ?? "/user-settings",
    meta: indexXAxdlEefrUMeta || {},
    alias: indexXAxdlEefrUMeta?.alias || [],
    redirect: indexXAxdlEefrUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/proper-plan/proper-plan/pages/user-settings/index.vue").then(m => m.default || m)
  }
]